<input #radioInput
       type="radio"
       [id]="id+'-input'"
       [name]="name"
       [disabled]="isDisabled"
       [value]="value"
       [ngModel]="selectedValue">
<span class="check-button"
      [id]="id+'-span'"
      (click)="toggleSelection(radioInput)">
	<ng-content></ng-content>
</span>
