import {NgModule} from '@angular/core';
import {SelectButtonComponent} from './select-button.component';
import {FormsModule} from '@angular/forms';

@NgModule({
	declarations: [SelectButtonComponent],
	imports: [FormsModule],
	exports: [SelectButtonComponent]
})
export class SelectButtonModule {
}
