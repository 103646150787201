import {Component, HostBinding, Input, OnInit} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR, Validators} from '@angular/forms';

@Component({
	selector: 'aga-select-button[id]',
	templateUrl: './select-button.component.html',
	styleUrls: ['./select-button.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: SelectButtonComponent,
			multi: true
		}
	]
})
export class SelectButtonComponent implements OnInit, ControlValueAccessor, Validators {
	@Input() id: string;
	@Input() name: string;
	@Input() allowDeselect: boolean;
	@Input() value: any;

	onChange: any = () => {
	};
	onTouch: any = () => {
	};
	@Input('disabled') isDisabled: boolean;
	selectedValue: any;
	@HostBinding('attr.id') elementId;

	ngOnInit(): void {
		if (!this.id) {
			throw new Error(`Select button attribute 'id' is required`);
		}
		this.elementId = this.id;
	}

	registerOnChange(fn: any): void {
		this.onChange = fn;
	}

	registerOnTouched(fn: any): void {
		this.onTouch = fn;
	}

	setDisabledState(isDisabled: boolean): void {
		this.isDisabled = isDisabled;
	}

	writeValue(value: string): void {
		this.selectedValue = value;
	}

	toggleSelection(radioInput: HTMLInputElement): void {
		if (!this.isDisabled) {
			if (radioInput.checked && this.allowDeselect) {
				this.selectedValue = null;
				radioInput.checked = false;
			} else {
				this.selectedValue = this.value;
				radioInput.click();
			}
			this.onChange(this.selectedValue);
			this.onTouch(this.selectedValue);
		}
	}
}
